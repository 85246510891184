import { graphql } from 'gatsby';
import React, { useState } from 'react'
import Layout from '../layouts';
import "../styles/categories.scss"
import 'uikit/dist/css/uikit.css'
import useSiteMetadata from "../utils/useSiteMetadata"
import loadable from '@loadable/component'
import CustomMarkdown from '../utils/customMarkdown';
import RelatedCategories from '../components/related-categories/related-categories';

const CategoryArticle = loadable(() => import('../components/category-article/category-article'))


export const query = graphql`
  query BlogCategories($id: Int) {
    strapiBlogCategories(strapiId: { eq: $id }) {
        CategoryName
        CategorySlug
        Canonical
        Description
        articles {
          created_at
          published_at
          Excerpt
          FeaturedImage {
            localFile {
              childImageSharp {
                
                gatsbyImageData
              }
            }
          }
          Title
          blog_category
          slug
          PageContent
          
        }
        RelatedCategories {
          Title
          blog_categories {
            CategoryName
            CategorySlug
          }
        }
      }
  }
`;



const Categories = ({ data }) => {

  const { CategoryName, articles } = data.strapiBlogCategories

  const categoryNameLength = CategoryName.length
  let postsPerPage = 6;
  const [showMore, setShowMore] = useState(postsPerPage);

  // sort articles by created_at, ASC

  data.strapiBlogCategories.articles.sort(function (firstArticle, secondArticle) {

    let secondDate = secondArticle.published_at ? secondArticle.published_at : secondArticle.created_at;
    let firstDate = firstArticle.published_at ? firstArticle.published_at : firstArticle.created_at;

    return new Date(secondDate) - new Date(firstDate);
  });


  const { siteUrl: url } = useSiteMetadata()

  return (
    <Layout 
    SEOTitle={data.strapiBlogCategories.CategoryName || data.strapiBlogCategories.Title} 
    SEODescription={''} 
    footerDisclaimer={''}
    canonical={data.strapiBlogCategories.Canonical ? data.strapiBlogCategories.Canonical : `${url}/${data.strapiBlogCategories.CategorySlug}`}>

      <section className={articles.length !== 0 ? "blog-articles-container padding-bottom" : "blog-articles-container"}>
        <div className='uk-width-1-1 category-title-container '>
          <div className='category-title-section'>
            <h1>{CategoryName}</h1>
            {categoryNameLength >= 70 ? '' : <CustomMarkdown>{data.strapiBlogCategories.Description}</CustomMarkdown>}
          </div>
        </div>

        <div className={articles.length !== 0 ? "blog-articles-wrapper lateral-padding" : "blog-articles-wrapper-second lateral-padding"}>
          <div data-uk-grid='true' className='uk-grid-match'>
            {
              articles?.slice(0, showMore).map((item, index) => {
                return <article className='uk-width-1-1 uk-width-1-3@s uk-padding-small article-container'>
                  <CategoryArticle
                    key={index}
                    title={item.Title}
                    excerpt={item.Excerpt}
                    CTAText={item.Title}
                    CTAUrl={item.slug}
                    image={item.FeaturedImage?.localFile.childImageSharp.gatsbyImageData}
                  />
                </article>
              })
            }
          </div>

          <div className="carousel-action" >
            {articles.length > postsPerPage && showMore < articles.length ? (
              <div className='read-more-btn_container padding-top '>
                <button onClick={() => setShowMore((prevPosts) => prevPosts + postsPerPage)} className='read-more-btn'>show more</button>
              </div>
            ) : null}

          </div>
        </div>
      </section>






      {data.strapiBlogCategories.RelatedCategories !== null ?
        <RelatedCategories
          slug={data.strapiBlogCategories.CategorySlug}
          categories={data.strapiBlogCategories.RelatedCategories}
        />
        : ""}





    </Layout>
  )
}

export default Categories