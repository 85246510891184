import React from "react"
import "../paragraph/paragraph.scss"
import "./related-categories.scss";
import { useStaticQuery, graphql } from "gatsby"
import largeArrow from "../../assets/arrow-large-2.webp";
import { Link } from "gatsby"


const RelatedCategories = ({ slug, categories }) => {


    return (
        <>
            <div className="bg_color">
                <div className="related-categories-container">

                    <div>
                        <h2 className="carousel-cat-title">{categories.Title}</h2>
                    </div>


                    <div className="categories-cards">
                        <div id="category-cta-card-grid" className="cta-card-grid ">
                            <div className="uk-slider uk-slider-container" uk-slider="autoplay: true autoplay-interval: 1000">
                                <div className="uk-position-relative uk-visible-toggle slider-content" tabIndex="-1">
                                    <div className="uk-slider-container uk-light">
                                        <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-2@s uk-child-width-1-3@m">
                                            {
                                                categories.blog_categories.map((item, index) => {

                                                    return (
                                                        <div key={index}>
                                                            <li className="category-box">
                                                                <div key={index} className={`category-cta-card uk-animation-scale-up category-link`}>
                                                                    <div className="uk-card uk-card-default uk-margin cta-card-carousel" uk-grid>

                                                                        <div className="card-body-carousel">
                                                                            <div className="uk-card-body category-cta-card-body">

                                                                                <div><Link to={`/${item.CategorySlug}`} className="uk-card-title category-card-title">
                                                                                    {item.CategoryName.length > 28 ?
                                                                                        item.CategoryName = item.CategoryName.substring(0, 28) + '...'
                                                                                        : item.CategoryName}
                                                                                </Link></div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>


                                    <a className="arrow-position uk-position-center-left uk-position-small uk-hidden-hover uk-icon uk-slidenav-previous uk-slidenav" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" role="button"></a>
                                    <a className="arrow-position uk-position-center-right uk-position-small uk-hidden-hover uk-icon uk-slidenav-next uk-slidenav" href="#" data-uk-slidenav-next="false" uk-slider-item="next" role="button"></a>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div >
        </>
    )






}
export default RelatedCategories